<template>
    <PageHeader :title="title" /> 

    <b>dsd</b>
</template>

<script>
import PageHeader from "@/components/page-header";

export default{
    components: {
        PageHeader
    },
    data(){
        return {
            title: this.$t('clients'),
            form: '',
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "id",
                    align: "left",
                    status: true
                },
            ]
        }
    }
}
</script>